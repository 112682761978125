


import React, { useState, useEffect } from 'react';

import { PieChart } from 'react-minimal-pie-chart';
import './predictionstyles.css';
import { Link, useNavigate } from 'react-router-dom';
function parsePercentage(value) {
  return typeof value === 'string' && value.includes('%')
    ? parseFloat(value.replace('%', ''))
    : parseFloat(value) * 100;
}


function PersonPieChart({ data }) {
  // Calculate percentages
  // const total = data.naturalness + data.coherence + data.understandability;
  // const naturalnessPercentage = (data.naturalness / total) * 100;
  // const coherencePercentage = (data.coherence / total) * 100;
  // const understandabilityPercentage = (data.understandability / total) * 100;
  // const overallPercentage = (data.overall / total) * 100;
  const naturalnessPercentage = parsePercentage(data.naturalness);
  const coherencePercentage = parsePercentage(data.coherence);
  const understandabilityPercentage = parsePercentage(data.understandability);

  // Define colors based on percentages
  const colors = [
    { title: 'Naturalness', value: naturalnessPercentage, color: '#2874a6' },
    { title: 'Coherence', value: coherencePercentage, color: '#16a085 ' },
    { title: 'Understandability', value: understandabilityPercentage, color: '#212f3c ' },
  ];

  return (
    <PieChart
      data={colors}
      style={{ fontWeight: 'bold', fontSize: '3px',color:"white" }}
      label={({ dataEntry }) => `${dataEntry.title}: ${dataEntry.value}%`}
      labelStyle={{
       
        fontFamily: 'sans-serif',
        fill: '#ffffff', // Change this to your desired color
      }}
      labelPosition={55}
      
    />
  );
}

export default function AllPrediction(){
  const [username, setUsername] = useState("");
  const [jsonData, setJsonData] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [showTotal, setShowTotal] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('messages');
    navigate('/');
  };
  useEffect(() => {
		// Retrieve username from session storage
		const usernameFromSession = sessionStorage.getItem("username");
		

		if (usernameFromSession) {
			const userId = usernameFromSession.split("_")[0];
			setUsername(userId);
			// setUsername(usernameFromSession);
		} else {
			// Redirect or handle the case when the username is not available in session
			navigate("/"); // Redirect to login page
		}
		
	}, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('data.json');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setJsonData(data);
      } catch (error) {
        console.error('Error fetching JSON data:', error);
      }
    };

    fetchData();
  }, []);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('file:///C:/deployment/ai_virtual_assistant/output/data_bkp.json');
  //       console.log('Response status:', response.status);
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch data');
  //       }
  //       const data = await response.json();
  //       setJsonData(data);
        
  //     } catch (error) {
  //       console.error('Error fetching JSON data:', error);
  //     }
  //   };
  
  //   fetchData();
  // }, []);
  
  



  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('data.json');
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch data');
  //       }
  //       const data = await response.json();
  //       setJsonData(data);
  //     } catch (error) {
  //       console.error('Error fetching JSON data:', error);
  //     }
  //   };

  //   fetchData();

  //   // Schedule API call at 8:25 PM IST daily
  //   const now = new Date();
  //   const millisTill840 = new Date(
  //     now.getFullYear(),
  //     now.getMonth(),
  //     now.getDate(),
  //     5,
  //     20,
  //     0,
  //     0
  //   ) - now;
  //   // if (millisTill840 < 0) {
  //   //   millisTill840 += 86400000; // it's after 8:40 PM, try 8:40 PM tomorrow.
  //   // }
  //   setTimeout(function () {
  //     fetchPredictionAll(); // Call your API function here
  //   }, millisTill840);
  // }, []);


  // const fetchPredictionAll = async () => {
  //   try {
  //     const response = await fetch('http://localhost:8000/chat/submit/prediction_all', {
  //       method: 'POST', // Specify the HTTP method
  //       headers: {
  //         'Content-Type': 'application/json', // Specify the content type if sending JSON data
  //         // Add any other headers as needed
  //       },
  //       body: JSON.stringify({}) // If you have data to send in the body, stringify it here
  //     });
  
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch prediction data');
  //     }
  
  //     const data = await response.json();
  //     console.log('Prediction data fetched:', data);
  //     // You can process the fetched data as needed
  //   } catch (error) {
  //     console.error('Error fetching prediction data:', error);
  //   }
  // };
  
  // const fetchPredictionAll = async () => {
  //   try {
  //     const response = await fetch('http://localhost:8000/chat/submit/prediction_all');
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch prediction data');
  //     }
  //     const data = await response.json();
  //     console.log('Prediction data fetched:', data);
  //     // You can process the fetched data as needed
  //   } catch (error) {
  //     console.error('Error fetching prediction data:', error);
  //   }
  // };

  const handlePersonClick = (person) => {
    setSelectedPerson(person);
    setShowTotal(false);
  };

  if (!jsonData) {
    return <div>Loading...</div>;
  }
  const handleTotalClick = () => {
        setShowTotal(true);
      };

  return (
    <div className="container2">
    
      <div className="data-section" style={{display:"flex"}}>
        {Object.keys(jsonData).map((user) => (
          <div key={user} >
         
            <button          style={{ 
    border: '1px solid black',
    backgroundColor: 'white',
    borderRadius: '5px 5px 0 0',
    color: 'black',
    fontSize: '16px',
    padding: '10px 20px',
    cursor: 'pointer',
    borderBottom: 'none',
    marginBottom: '-1px'
}}
      onClick={() => handlePersonClick(user)}>{user}</button>
            {selectedPerson === user && (
              <div>
                {!showTotal && <button onClick={handleTotalClick}>Total</button>}
                {!showTotal && <button onClick={() => setShowTotal(false)}>Breakdown</button>}
                {showTotal && (
                  // <PieChart
                  //   data={[
                  //     { title: 'Accuracy', value: jsonData[user].Total, color: '#4CAF50' },
                  //     { title: 'Inconsistence', value: 100 - jsonData[user].Total, color: '#f44336' },
                  //   ]}
                  //   style={{ fontWeight: 'bold', fontSize: '2px' }}
                  //   label={({ dataEntry }) => `${dataEntry.title}: ${dataEntry.value}%`}
                  // />
                  <PieChart
  data={[
    { 
      title: 'Accuracy', 
      value: parseFloat(jsonData[user].Total), // Convert "45%" to 45
      color: '#4CAF50' 
    },
    { 
      title: 'Inconsistence', 
      value: 100 - parseFloat(jsonData[user].Total), 
      color: '#e74c3c ' 
    },
  ]}
  label={({ dataEntry }) => `${dataEntry.title}: ${dataEntry.value.toFixed(2)}%`}
  labelStyle={{
    fontSize: '5px',
    fontFamily: 'Arial',
    fill: '#ffffff', // Change text color if needed
    fontWeight: 'bold',
  }}
  labelPosition={55}
/>

                  
                )}
                {!showTotal && (
                  <ul style={{width:"350px"}}>
                    {jsonData[user].BreakDown.map((item, index) => (
                      <li key={index}>
                        {/* Your breakdown data */}
                        <div style={{display:"flex",gap:"2px"}}>
                  <div className="squre" style={{
                  height:"10px",
                  width:"10px",
                  background:"#2874a6",
                  marginTop:"6px",
                 
                  }}> </div><p>Naturalness: {item.scores.naturalness}</p>
                  </div>
                  <div style={{display:"flex",gap:"2px"}}>
                  <div className="squre" style={{
                  height:"10px",
                  width:"10px",
                  background:"#16a085",
                  marginTop:"6px",
                 
                  }}> </div><p>Coherence: {item.scores.coherence}</p>
                  </div>
                  <div style={{display:"flex",gap:"2px"}}>
                  <div className="squre" style={{
                  height:"10px",
                  width:"10px",
                  background:"#212f3c ",
                  marginTop:"6px",
                 
                  }}> </div><p>Understandability: {item.scores.understandability}</p>
                  </div>
                  <div style={{display:"flex",gap:"2px"}}>
                  <div className="squre" style={{
                  height:"10px",
                  width:"10px",
                  background:"#8e44ad",
                  marginTop:"6px",
                 
                  }}> </div> <p>Overall: {item.scores.overall}</p>
                  </div>
                  <div style={{display:"flex",gap:"2px"}}>
                  <div className="squre" style={{
                  height:"10px",
                  width:"10px",
                  marginTop:"6px",
                 
                  }}> </div> <p>Query: {item.query}</p>
                  </div>
                        <PersonPieChart data={item.scores} />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}



